import { TextInput as MantineTextInput } from "@mantine/core";
import { InputLabel, inputSharedStyles } from "./sharedStyles";

const TextInputV2 = ({
  label,
  disabled,
  error,
  info,
  message,
  classNames,
  required,
  size,
  value,
  ...props
}: BaseInputProps) => {
  return (
    <MantineTextInput
      size={size}
      label={
        label ? (
          <InputLabel
            label={label}
            disabled={disabled}
            info={info}
            error={!!error}
            required={required}
          />
        ) : undefined
      }
      description={message}
      classNames={{
        description: inputSharedStyles.description({
          error: !!error,
          className: classNames?.description,
        }),
        input: inputSharedStyles.input({ className: classNames?.input }),
        wrapper: inputSharedStyles.wrapper({ disabled, className: classNames?.wrapper }),
        rightSection: inputSharedStyles.rightSection({
          onClick: !!props.rightSectionProps?.onClick,
          className: classNames?.rightSection,
        }),
      }}
      error={error}
      disabled={disabled}
      value={value}
      {...props}
    />
  );
};

export default TextInputV2;

type BaseInputProps = {
  label?: string;
  message?: string;
  info?: string;
  error?: string | boolean;
  disabled?: boolean;
  leftSection?: React.ReactNode;
  rightSection?: React.ReactNode;
} & Omit<
  React.ComponentProps<typeof MantineTextInput>,
  "rightSectionProps" | "leftSectionProps"
> & {
    rightSectionProps?: React.ComponentProps<"div">;
    leftSectionProps?: React.ComponentProps<"div">;
  };
