import { useForm, zodResolver } from "@mantine/form";
import { z } from "zod";

const getDefaults = <Schema extends z.AnyZodObject>(schema: Schema) => {
  return Object.fromEntries(
    Object.entries(schema.shape).map(([key, value]) => {
      if (value instanceof z.ZodDefault) return [key, value._def.defaultValue()];
      return [key, undefined];
    }),
  );
};

export const useZodForm = <Schema extends z.ZodObject<Record<string, z.ZodTypeAny>>>(
  schema: Schema,
  options?: {
    defaultValues: z.infer<Schema> | undefined;
  },
) => {
  const initialValues = options?.defaultValues || getDefaults(schema);
  const form = useForm<z.infer<Schema>>({
    initialValues: {
      ...initialValues,
    },
    validate: zodResolver(schema),
    validateInputOnChange: true,
  });

  return form;
};
